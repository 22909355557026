import { ChakraProvider } from '@chakra-ui/react'

import { QueryClientProvider } from '@tanstack/react-query'

import { GeoLocationProvider } from './context/GeoLocationContext'
import { GoogleMapProvider } from './context/GoogleMapContext'
import { ParkingProvider } from './context/ParkingContext'

import { Routes } from './routes'

import { queryClient } from './services/react-query'

import { theme } from './styles/theme'

import './styles/autocomplete.scss'

function App() {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <GeoLocationProvider>
          <GoogleMapProvider>
            <ParkingProvider>
              <Routes />
            </ParkingProvider>
          </GoogleMapProvider>
        </GeoLocationProvider>
      </QueryClientProvider>
    </ChakraProvider>
  )
}

export default App
