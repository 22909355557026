export type ProviderProps = {
  children: React.ReactNode
}

export type ActiveMarker = {
  id: string
  position: google.maps.LatLngLiteral
}

export enum ViewType {
  LIST,
  MAP,
}

export type GoogleMapData = {
  zoom: number
  setZoom: React.Dispatch<React.SetStateAction<number>>
  activeMarker: ActiveMarker | null
  setActiveMarker: React.Dispatch<React.SetStateAction<ActiveMarker | null>>
  viewType: ViewType
  setViewType: React.Dispatch<React.SetStateAction<ViewType>>
}
