import React from 'react'

import { Parking } from '../@types/entities/entities.types'

import type {
  ParkingContextData,
  ProviderProps,
} from '../helpers/types/context/parking.types'

export const ParkingContext = React.createContext<ParkingContextData>(
  {} as ParkingContextData
)

export function ParkingProvider({ children }: ProviderProps) {
  const [parkings, setParkings] = React.useState<Parking[]>([])

  return (
    <ParkingContext.Provider value={{ parkings, setParkings }}>
      {children}
    </ParkingContext.Provider>
  )
}
