import React from 'react'

import type {
  ActiveMarker,
  GoogleMapData,
  ProviderProps,
} from '../helpers/types/context/googlemap.types'
import { ViewType } from '../helpers/types/context/googlemap.types'

export const GoogleMapContext = React.createContext<GoogleMapData>(
  {} as GoogleMapData
)

export function GoogleMapProvider({ children }: ProviderProps) {
  const [zoom, setZoom] = React.useState<number>(16)
  const [activeMarker, setActiveMarker] = React.useState<ActiveMarker | null>(
    null
  )
  const [viewType, setViewType] = React.useState<ViewType>(ViewType.LIST)

  return (
    <GoogleMapContext.Provider
      value={{
        zoom,
        setZoom,
        activeMarker,
        setActiveMarker,
        viewType,
        setViewType,
      }}
    >
      {children}
    </GoogleMapContext.Provider>
  )
}
