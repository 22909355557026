import { Stack, StackProps } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/image'
import { Spinner } from '@chakra-ui/spinner'

export default function Loading({
  width = '100vw',
  height = '100vh',
}: StackProps) {
  return (
    <Stack
      direction="column"
      spacing="1rem"
      alignItems="center"
      justifyContent="center"
      width={width}
      height={height}
    >
      <Image src="https://parkoff-prod.s3.us-east-2.amazonaws.com/assets/svg/logo.svg" />
      <Spinner
        thickness="0.25rem"
        speed="0.65s"
        emptyColor="gray.200"
        color="parkoff.orange.400"
        size="xl"
      />
    </Stack>
  )
}
