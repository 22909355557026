import React from 'react'

import { Routes as ReactRouter, Route, Outlet } from 'react-router-dom'

import Loading from '../pages/Loading'

const Maps = React.lazy(() => import('../pages/index'))

export function Routes() {
  return (
    <ReactRouter>
      <Route
        path="/"
        element={
          <React.Suspense fallback={<Loading />}>
            <Maps />
          </React.Suspense>
        }
      />
    </ReactRouter>
  )
}
