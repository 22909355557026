import React from 'react'

import type {
  GeoLocationData,
  ProviderProps,
} from '../helpers/types/context/geolocation.types'

export const GeoLocationContext = React.createContext<GeoLocationData>(
  {} as GeoLocationData
)

export function GeoLocationProvider({ children }: ProviderProps) {
  const [location, setLocation] = React.useState<google.maps.LatLngLiteral>({
    lat: -19.9262762,
    lng: -43.938171,
  })
  const [userLocation, setUserLocation] =
    React.useState<google.maps.LatLngLiteral>({
      lat: 0,
      lng: 0,
    })

  return (
    <GeoLocationContext.Provider
      value={{ location, setLocation, userLocation, setUserLocation }}
    >
      {children}
    </GeoLocationContext.Provider>
  )
}
